export default {
  CREATE: 'plus',
  EDIT: 'edit',
  REMOVE: 'red remove circle',
  CONNECT: 'green play',
  DISCONNECT: 'remove circle',
  DEFAULT: 'blue pin',
  LOCK: 'lock',
  POWER: 'red power',
  EXPAND: 'angle down',
  DROPDOWN: 'dropdown',
  REDIRECT: 'forward mail',
  BROWSE: 'yellow folder open',
  OFFLINE: 'plug',
  FILTER: 'filter',
  SEND: 'send',
  CONFIGURE: 'grey configure',
  CLOSE: 'grey remove',
  CANCEL: 'remove',
  DATE: 'calendar',
  MENU: 'content',
  NOTIFICATION: 'yellow bell',

  DISABLE: 'grey remove',
  ENABLE: 'green checkmark',

  RELOAD: 'blue refresh',
  REFRESH_PLAIN: 'refresh',
  REFRESH_COLORED: 'green refresh',
  HUB: 'sitemap',
  MESSAGE: 'mail outline',
  FILELIST: 'browser',
  HASH: 'blue database',

  HOME: 'home',
  FILELISTS_PLAIN: 'browser',
  FILELISTS_COLORED: 'blue browser',
  MESSAGES_PLAIN: 'comments',
  MESSAGES_COLORED: 'blue comments',
  SEARCH: 'search',
  SEARCH_ALTERNATES: 'green zoom',
  FAVORITE: 'yellow star',
  HUBS_PLAIN: 'sitemap',
  HUBS_COLORED: 'blue sitemap',
  QUEUE_PLAIN: 'arrow circle down',
  QUEUE_COLORED: 'olive arrow circle down',
  TRANSFERS_PLAIN: 'exchange',
  TRANSFERS_COLORED: 'teal exchange',
  SETTINGS: 'configure',
  EVENTS_PLAIN: 'history',
  EVENTS_COLORED: 'blue history',
  FILES_PLAIN: 'file',
  FILES_COLORED: 'blue file',
  LOGOUT: 'sign out',
  EXTENSION: 'puzzle',

  UPLOAD: 'red upload',
  DOWNLOAD: 'green download',
  DOWNLOAD_TO: 'blue download',
  FOLDER: 'yellow folder',
  SAVE_PLAIN: 'checkmark',
  SAVE_COLORED: 'green checkmark',

  STOP: 'red stop',
  PAUSE: 'blue pause',
  PLAY: 'green play',

  CLEAR: 'blue erase',
  OPEN: 'external',
  FIND: 'find',
  OPTIONS: 'list',
  DETAILS: 'info circle',
  EXTERNAL: 'external',
  VIEW: 'external',
  // BROWSE: 'external',

  VERBOSE: 'bars',
  INFO: 'blue info',
  WARNING: 'yellow warning sign',
  ERROR: 'red warning circle',
  SUCCESS: 'green check circle',

  TIP: 'idea',
  NOTE: 'blue warning',

  ANY: 'star',
  FILE: 'file outline',
  USER: 'user',

  COPY: 'copy',
  MAGNET: 'red magnet',
  IGNORE: 'red ban',
  UNIGNORE: 'ban',
};
